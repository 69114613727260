import React from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import { Address } from '@wix/ambassador-addresses-web/types';
import RadioButton from '../RadioButton';
import styles from './AddressSelectionModal.scss';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import { SectionNotification, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/SectionNotification';
import { TextButton } from '../TextButton';
import dataHooks from '../../data-hooks';
import { SetDeliveryAddressPayload } from '../../../../state/checkout/checkout.actions.types';
import {
  convertMembersAddressToOloAddress,
  convertToOloAddressMembersAddress,
} from '../../../../state/session/session.probe';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import AddressInformationDelivery from '../AddressInformation/AddressInformationDelivery';
import { Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import _ from 'lodash';
import DrillDownComponent from '../DrillDownComponent';
import { ValidateAddressReason, Address as OloAddress } from '@wix/restaurants-client-logic';
import { SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';

export interface DisplayableAddress extends Address {
  error?: string;
}

export interface AddressSelectionModalProps {
  addresses: DisplayableAddress[];
  onRequestClose: () => void;
  defaultAddressId?: string;
  setDeliveryAddress: (address: SetDeliveryAddressPayload) => void;
  t: TranslationFunction;
  forceAddressFormVisibility?: boolean;
  restaurant: Restaurant;
  dispatchTime?: number;
  totalOrderPrice: number;
  setDeliveryAddressFromForm: () => void;
  addressInputError?: ValidateAddressReason;
  selectedAddressOption?: OloAddress;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
}

const AddressSelectionModal: React.FC<AddressSelectionModalProps> = ({
  onRequestClose,
  addresses,
  defaultAddressId,
  setDeliveryAddress,
  t,
  forceAddressFormVisibility,
  restaurant,
  dispatchTime,
  totalOrderPrice,
  setDeliveryAddressFromForm,
  selectedAddressOption,
  saveAddressToServer,
}) => {
  const [selectedAddressId, setSelectedAddressId] = React.useState(defaultAddressId);
  const [isAddressFormVisible, setIsAddressFormVisible] = React.useState(forceAddressFormVisibility);

  const handleOkClick = React.useCallback(() => {
    const selectedAddress = addresses.find((da) => da.id === selectedAddressId);
    if (selectedAddress && !isAddressFormVisible) {
      setDeliveryAddress({ address: convertMembersAddressToOloAddress(selectedAddress) });
      onRequestClose();
    } else if (isAddressFormVisible && selectedAddressOption) {
      saveAddressToServer({ address: convertToOloAddressMembersAddress(selectedAddressOption) });
      setDeliveryAddressFromForm();
      onRequestClose();
    }
  }, [
    saveAddressToServer,
    setDeliveryAddress,
    onRequestClose,
    addresses,
    selectedAddressId,
    isAddressFormVisible,
    setDeliveryAddressFromForm,
    selectedAddressOption,
  ]);

  const handleCloseClick = React.useCallback(() => {
    if (isAddressFormVisible) {
      setIsAddressFormVisible(false);
    } else {
      onRequestClose();
    }
  }, [isAddressFormVisible, onRequestClose]);

  const footer = (
    <ModalLayoutBasicFooter
      spaceBetween
      secondaryButtonContent="+ Add new address"
      onSecondaryClick={() => setIsAddressFormVisible(true)}
      onOkClick={handleOkClick}
      showSecondaryButton={!isAddressFormVisible}
    />
  );

  const list = (
    <React.Fragment>
      {addresses.map((address) => {
        return (
          address.id && (
            <React.Fragment key={address.id}>
              <div className={styles.optionWrapper}>
                <RadioButton
                  name="restaurants-address-selection-modal-option"
                  value={address.id}
                  className={styles.option}
                  checked={selectedAddressId === address.id}
                  onChange={() => setSelectedAddressId(address.id)}
                  withFocusRing
                >
                  <p data-hook={dataHooks.addressSelectionModalOptionLabel}>{address.addressLine1}</p>
                  {address.addressLine2 && <p>{address.addressLine2}</p>}
                  {address.hint && <p>{address.hint}</p>}
                </RadioButton>

                <TextButton priority={TEXT_BUTTON_PRIORITY.link}>Edit</TextButton>
              </div>

              {address.error && (
                <SectionNotification
                  type="error"
                  className={styles.error}
                  data-hook={`${dataHooks.addressSelectionModalOptionError}-${address.id}`}
                >
                  <SectionNotification.Icon icon={<Error />} />
                  <SectionNotification.Text>{t(address.error)}</SectionNotification.Text>
                </SectionNotification>
              )}

              <div className={styles.divider} />
            </React.Fragment>
          )
        );
      })}
    </React.Fragment>
  );

  const form = (
    <AddressInformationDelivery
      restaurant={restaurant}
      dispatchTime={dispatchTime}
      totalOrderPrice={totalOrderPrice}
      onAddressInputBlur={_.noop}
      onAddressInputSelect={_.noop}
      showAddressLine2
      forceCommentInputVisibility
    />
  );

  return (
    <ModalLayoutSelection
      header="Choose Delivery Address"
      onCloseClick={handleCloseClick}
      footer={footer}
      data-hook={dataHooks.addressSelectionModal}
      scrollable
    >
      <DrillDownComponent primary={list} secondary={form} isDrilled={isAddressFormVisible} />
    </ModalLayoutSelection>
  );
};

AddressSelectionModal.displayName = 'AddressSelectionModal';

export default translate()(AddressSelectionModal);
